<template>
    <div>
    <div style="position: relative;">
        <video src="https://content.marisolmalibu.com/storage/uploads/2021/05/17/videoplayback_uid_60a2ac35e2fb2.mp4" style="height: 100vh; width: 100%; object-fit: cover; position: absolute; top: 0px;" muted autoplay loop playsinline></video>

        <!-- intro -->
    <v-container fluid>
      <v-row>
        <v-col class="pa-0 intro position-relative">
          <!-- <video
            style="object-fit: cover"
            class="intro__asset"
            loop
            playsinline
            width="100%"
            height="100%"
            :autoplay="autoplay"
          >
            <source src="@/assets/img/videoplayback.mp4" />
          </video> -->
          <v-row no-gutters justify="center" class="flex-column fill-height">
            <v-col cols="auto" class="animation" style="animation-delay: 2s">
              <div class="logo-header" v-if="$vuetify.breakpoint.smAndUp"></div>
              <router-link to="/" v-else>
                <v-img
                  alt="Marisol Logo"
                  class="shrink logo-header mx-auto"
                  contain
                  src="@/assets/icons/MariSolMalibu_logo.svg"
                  transition="scale-transition"
                />
              </router-link>
              <!-- btn para el menú mobile -->
              <div class="text-center mt-6 d-sm-none">
                <v-btn
                  @click="$emit('open')"
                  :ripple="false"
                  text
                  class="letter-spacing-0 not-text-transform"
                >
                  <span class="continuos-texts white--text">Menu</span>
                </v-btn>
              </div>
              <!-- /btn para el menú mobile -->
            </v-col>
            <v-col
              cols="auto"
              class="position-relative my-auto in-property-margin"
            >
              <v-row no-gutters justify="center" align="center">
                <v-col cols="auto" class="text-center line-height-title-intro">
                  <p class="continuos-texts block__fifth__margin-p line-height-description pre-line white--text"> Coming soon </p>
                    <h2 class="titles-medium rewrite-in-hd white--text line-height-title-2 sabon-regular my-6 my-md-12"><p>Be the  <em><span class="sabon-italic">first</span></em> to visit</p>
                    </h2>

                    <mailchimp-subscribe
                        url="https://marisolmalibu.us2.list-manage.com/subscribe/post-json"
                        list-id="e881d84d7d"
                        user-id="940f0546d0afa3cb202e6a56c"
                        @error="onError()"
                        @success="onSuccess()"
                    >
                        <template v-slot="{ subscribe, setEmail, error, success, loading }">
                        <form @submit.prevent="subscribe">
                            <v-container>
                                <v-row justify="center">
                                    <v-col cols="11" sm="auto" class="px-0 py-0 py-sm-3">
                                        <input type="email" @input="setEmail($event.target.value)" placeholder="enter email address" class="newsletter-email-input" />
                                    </v-col>
                                    <v-col cols="11" sm="auto" class="px-0 py-0 py-sm-3">
                                        <!-- <v-btn type="submit" color="black" dark tile height="40" :ripple="false"><p class="my-0" style="text-transform: none">Subscribe to updates</p></v-btn> -->

                                        <button type="submit" class="newsletter-email-btn">Subscribe for updates</button>
                                    </v-col>
                                    <!-- <input type="email"  /> -->
                                    <!-- <button type="submit">Submit</button> -->
                                    <div v-if="error"></div>
                                    <div v-if="success"></div>
                                    <div v-if="loading"></div>
                                </v-row>
                            </v-container>
                        </form>
                        </template>
                    </mailchimp-subscribe>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <!-- /intro -->
    
    </div>

    <div class="block-second" v-if="false"> 

      <v-container fluid class="pa-12">
        <v-row>
          <v-col>
            <v-img src="https://content.marisolmalibu.com/storage/uploads/2023/07/19/MM-Hero-Zero-2-Edit_uid_64b772be0d31b.jpg" height="75vh" class="align-center text-center white--text">
              <!-- <div style="font-size: 55px;">
                <span class="sabon-italic d-block d-md-inline-block">Explore</span> <span class="sabon-regular d-block d-md-inline-block">Zero 2</span>
              </div>
              <br>
              <router-link to="/zero2" class="btn-2 letter-spacing-0 not-text-transform v-btn v-btn--has-bg v-btn--router v-btn--tile theme--light elevation-0 v-size--default white"><span class="v-btn__content"><span class="continuos-texts">Zero 2</span></span></router-link> -->
            </v-img>
          </v-col>
        </v-row>
      </v-container>

    </div>

        <v-snackbar
            v-model="snackbarEmail"
            top
            app
            color="black"
            class="text-center"
            >
            <div class="text-center">
                <span class="white--text continuos-texts">Thank you!</span>
            </div>
        </v-snackbar>
    </div>
</template>

<script>
import MailchimpSubscribe from 'vue-mailchimp-subscribe'

export default {
    components: {
        MailchimpSubscribe,
    },
    data(){
        return {
            snackbarEmail: false
        }
    },
    methods: {
        onSuccess: function () {
            this.snackbarEmail = true
            var aux = this;
            setTimeout(() => {
                aux.snackbarEmail = false
            }, 2000);
        },
        onError: function() {
            this.snackbarEmail = true
            var aux = this;
            setTimeout(() => {
                aux.snackbarEmail = false
            }, 2000);
        }
    }
}
</script>

